/* global serverConfig */

import { extendObservable, runInAction } from 'mobx'
import { createTransformer } from 'mobx-utils'
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import { ObjectID as ObjectId } from 'bson'
import jwtDecode from 'jwt-decode'
import querystring from 'querystring'
import store from 'store'
import Cookies from 'universal-cookie'
import { Permissions, User } from 'suredone-common'
import * as Sentry from '@sentry/react'

import { createApolloClient } from '../apollo'

const cookies = new Cookies()
const debug = require('debug')('sd:AuthStore')
window.cookies = cookies
window.Permissions = Permissions
window.ObjectId = ObjectId

if (!window.serverConfig) {
  window.serverConfig = {
    cognitoUserPoolId: 'us-east-1_tvFLrZ88D',
    cognitoClientId: '52fnqc5hikrqu5q4rjtti584op',
    stripePublicKey: 'pk_ArCkVpDB56nXXyxE6K0KwI645Md9L',
    release: 'local'
  }
}
const UserPoolId = serverConfig.cognitoUserPoolId
const ClientId = serverConfig.cognitoClientId
const IntercomAppId = serverConfig.intercomAppId
const IntercomEnabled = !!serverConfig.intercomAppId
const HotjarAppId = serverConfig.hotjarAppId
const HotjarEnabled = !!serverConfig.hotjarAppId
const serverOnline = window.serverConfig.online

export const states = Object.freeze({
  booting: 'BOOTING',
  confirming: 'CONFIRMING',
  loggedOut: 'LOGGED_OUT',
  loggedIn: 'LOGGED_IN',
  resetPassword: 'RESET_PASSWORD',
  wizardLoggedIn: 'WIZARD_LOGGED_IN',
  forceChangePassword: 'FORCE_CHANGE_PASSWORD',
  sendMFACode: 'SEND_MFA_CODE'
})

export default class AuthStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    this.userPool = window.userPool = new CognitoUserPool({
      UserPoolId,
      ClientId
    })
    this.initialHref = window.location.href
    this.initialPathname = window.location.pathname
    this.initialSearch = window.location.search
    const initialPathArray = this.initialPathname.split('/')
    this.isFromExternalAuthChannel = initialPathArray.length > 0 && (initialPathArray[1] === 'auth' || initialPathArray[1] === 'load')
    this.externalChannel = this.isFromExternalAuthChannel && initialPathArray?.length > 2 && initialPathArray[2]
    this.refresh = this.refresh.bind(this)
    this.changeUserPassword = this.changeUserPassword.bind(this)

    extendObservable(this, {
      // Internal state
      state: states.booting,
      states,
      cognitoUser: null,
      emailForLogin: null,
      jwtUser: null,
      lastPreLoginAction: null,
      apolloClient: null,
      loggingIn: false,
      redirectLoopDetected: this.areWeInARedirectLoop(),
      userAttributes: null,

      // Server online state
      serverOnline,
      serverOnlineSecondsUntilRetry: 10,

      // Version state
      releaseAtBoot: serverConfig.release,
      releaseNow: serverConfig.release,

      // Data from cognito
      token: null,
      user: null,
      permissions: null,

      // These appear as properties i.e. stores.auth.isAdmin
      get isAdmin () {
        return this.permissions && this.permissions.hasRole('PlatformAdmin')
      },
      get loggedIn () {
        return this.state === states.loggedIn
      },

      // Creates a linked with an embedded authToken in the querystring.
      // If you need to include query own querystring, pass an object in
      // the @query parameter.
      tokenLink: createTransformer((path, query = {}) => {
        const q = querystring.encode(
          Object.assign(query, { authToken: this.token })
        )
        return this.rootStore.api.baseURL + path + (q ? `?${q}` : '')
      })
    })
  }

  /**
   * Release checking polling.
   */
  async startCheckingRelease () {
    clearTimeout(this.releaseCheckTimeout)
    this.releaseCheckTimeout = setTimeout(async _ => {
      try {
        await this.checkRelease()
      } catch (e) {
        debug('error during releaseCheck', e)
      }
      this.startCheckingRelease()
    }, 60 * 1000)
  }

  stopCheckingRelease () {
    clearTimeout(this.releaseCheckTimeout)
  }

  async checkRelease () {
    // eslint-disable-next-line no-undef
    const { release } = await (await fetch('/config.json')).json()
    this.releaseNow = release
    debug({
      releaseAtBoot: this.releaseAtBoot,
      releaseNow: release
    })
    // Automatically refresh page if release changes while logged out
    if (this.state !== this.states.loggedIn && this.releaseNow !== this.releaseAtBoot) {
      window.location = window.location // eslint-disable-line no-self-assign
    }
  }

  /**
   * Internal utility to pull the current session from cognito library.
   */
  async getCognitoSession () {
    return new Promise((resolve, reject) => {
      if (this.cognitoUser) {
        this.cognitoUser.getSession((err, session) => {
          if (err) {
            return reject(err)
          }
          return resolve(session)
        })
      } else {
        return reject(new Error('never authenticated'))
      }
    })
  }

  /**
   * Internal utility to refresh the given cognito session. Returns a
   * promise for the new session
   */
  async refreshCognitoSession (session) {
    return new Promise((resolve, reject) => {
      if (this.cognitoUser) {
        this.cognitoUser.refreshSession(
          session.getRefreshToken(),
          (err, session) => {
            if (err) return reject(err)
            return resolve(session)
          }
        )
      } else {
        return reject(new Error('tried to refresh but never authenticated'))
      }
    })
  }

  /**
   * Internal utility to set the store state to a cognito session object
   * In case the Account is registered by new register UI, then is mandatory
   * to pass through wizard
   */

  setStoreState (user) {
    !user.legacyAccountId ? this.state = this.states.wizardLoggedIn : this.state = states.loggedIn
  }

  /**
   * Internal utility to set the store state to a cognito session object
   */
  setCurrentSession (session) {
    runInAction(() => {
      this.jwtUser = jwtDecode(session.idToken.jwtToken)
      this.user = User.fromJwt(this.jwtUser)
      debug({ jwtUser: this.jwtUser, normalized: this.user })
      if (session.isValid()) {
        this.token = session.idToken.jwtToken
        this.setStoreState(this.user)
        if (this.user.organizationId) {
          this.permissions = new Permissions(
            this.user.organizationId,
            this.user.roles,
            this.user.scopes
          )
        }
        this.apolloClient = createApolloClient(this.token, this.refresh)
        cookies.set('st', this.token, { path: '/' })
        if (Sentry.isInitialized()) {
          Sentry.configureScope(scope => {
            scope.setTag('sudo', !!this.user.sudoer)
            scope.setUser(this.user.getForSentry())
          })
        }
      } else {
        this.token = null
        this.state = states.loggedOut
        this.permissions = null
        this.apolloClient = null
        cookies.remove('st')
      }
    })
  }

  /**
   * Internal utility to set the store state to a cognito session object
   * WITHOUT CREATING A NEW APOLLO CLIENT
   */
  setCurrentSessionWithoutApolloClient (session) {
    runInAction(() => {
      this.jwtUser = jwtDecode(session.idToken.jwtToken)
      this.user = User.fromJwt(this.jwtUser)
      debug({ jwtUser: this.jwtUser, normalized: this.user })
      if (session.isValid()) {
        this.token = session.idToken.jwtToken
        this.state = states.loggedIn
        this.permissions = new Permissions(
          this.user.organizationId,
          this.user.roles,
          this.user.scopes
        )
        cookies.set('st', this.token, { path: '/' })
        if (Sentry.isInitialized()) {
          Sentry.configureScope(scope => {
            scope.setTag('sudo', !!this.user.sudoer)
            scope.setUser(this.user.getForSentry())
          })
        }
      } else {
        this.token = null
        this.state = states.loggedOut
        this.permissions = null
        this.apolloClient = null
        cookies.remove('st')
      }
    })
  }

  /**
   * Initializes the authentication state, bringing data about the current
   * user out of storage if we can find it.
   */
  async init () {
    // The temporaryUsername serves to persist the username before a login
    // is complated. This is useful between registration and confirmation
    // and during password resets. Even if the user navigates away or
    // follows an email link, the username is stored until login
    const temporaryUsername = store.get('temporaryUsername')
    const emailForLogin = store.get('emailForLogin')
    debug({ temporaryUsername })

    // The lastPreLoginAction variable tracks whether we were confirming or
    // resetting password. This is so email links (which don't
    // differentiate) can be routed to the correct view when user clicks
    // them
    const lastPreLoginAction = store.get('lastPreLoginAction')

    // Hydrate initial state
    runInAction(() => {
      // Use temporaryUsername if we have one, otherwise, try the cognito
      // sdk's persisted user
      if (temporaryUsername) {
        this.cognitoUser = new CognitoUser({
          Username: temporaryUsername,
          Pool: this.userPool
        })
      } else {
        this.cognitoUser = this.userPool.getCurrentUser()
      }
      this.emailForLogin = emailForLogin
      this.lastPreLoginAction = lastPreLoginAction
    })

    // Countdown to refresh if server is down
    if (!this.serverOnline && !this.serverOnlineTimer) {
      this.serverOnlineTimer = setInterval(() => {
        runInAction(() => {
          this.serverOnlineSecondsUntilRetry -= 1
        })
        if (this.serverOnlineSecondsUntilRetry <= 0) {
          window.location = window.location // eslint-disable-line no-self-assign
        }
      }, 1000)
    }

    this.startCheckingRelease()
    this.loadHotjar()
    await this.refresh()
  }

  /**
   * Loads up whatever session is in local storage and forces a logged in state.
   * Used to test the effects of expired sessions.
   */
  async forceLoadSession () {
    const session = await this.getCognitoSession()
    runInAction(() => {
      this.jwtUser = jwtDecode(session.idToken.jwtToken)
      this.user = User.fromJwt(this.jwtUser)
      this.token = session.idToken.jwtToken
      this.state = states.loggedIn
      this.loggingIn = false
      this.permissions = new Permissions(
        this.user.organizationId,
        this.user.roles,
        this.user.scopes
      )
      this.apolloClient = createApolloClient(this.token, this.refresh)
      cookies.set('st', this.token, { path: '/' })
    })
  }

  /**
   * Refreshes a user's token with cognito. Useful for updating the UI when
   * there are permissions or other token changes. Returns the current cognito
   * session's jwt token.
   *
   * Set skipApolloClientRefresh to true to not recreate the apollo client, so
   * it can continue whatever operation it is performing using the refreshed
   * token.
   */
  async refresh (skipApolloClientRefresh = false) {
    try {
      const initialSession = await this.getCognitoSession()
      const currentSession = await this.refreshCognitoSession(initialSession)
      if (skipApolloClientRefresh) {
        this.setCurrentSessionWithoutApolloClient(currentSession)
      } else {
        this.setCurrentSession(currentSession)
      }
      this.loadIntercom()
      this.addSegmentMiddlewares()
      this.identify()
      return currentSession.idToken.jwtToken
    } catch (e) {
      debug('error in init(), treating user as logged out', e)
      if (typeof runInAction === 'function') {
        runInAction(() => {
          this.state = states.loggedOut
        })
      } else {
        console.warn('runInAction no available')
        this.state = states.loggedOut
      }
      // Check to see if we are logged in in the legacy app, if so, logout
      if (cookies.get('sx') || cookies.get('sd')) {
        window.location = '/legacy-logout'
      }
    }
  }

  // Note If preferred_username is selected as an alias, the value can be provided only when an account is confirmed. The value cannot be provided during registration.
  async signUp (registerData) {
    const { email, password, first, last, userId } = registerData
    const emailLowecased = email.toLowerCase()
    const usernameForCognito = userId || new ObjectId().toString()
    return new Promise((resolve, reject) => {
      const atts = [
        new CognitoUserAttribute({ Name: 'email', Value: emailLowecased }),
        new CognitoUserAttribute({ Name: 'given_name', Value: first }),
        new CognitoUserAttribute({ Name: 'family_name', Value: last })
      ]
      store.set('temporaryUsername', usernameForCognito)
      store.set('emailForLogin', emailLowecased)
      store.set('lastPreLoginAction', 'signUp')
      this.userPool.signUp(usernameForCognito, password, atts, null, (err, result) => {
        debug('userPool.signUp', err, result)
        if (err) return reject(err)
        runInAction(() => {
          this.cognitoUser = result.user
          this.emailForLogin = emailLowecased
          this.state = states.confirming
        })
        resolve()
      })
    })
  }

  /**
   * Identifies user to segment if segment is installed.
   */
  identify () {
    if (window.analytics) {
      window.analytics.identify(this.user.id)
      window.analytics.group(this.user.organizationId)
    }
  }

  /**
   * Adds middleware to segment calls
   */
  addSegmentMiddlewares () {
    if (window.analytics) {
      window.analytics.addSourceMiddleware(({ payload, next, integrations }) => {
        // Delete old date
        delete payload?.obj?.sudoer
        delete payload?.obj?.sudoerId
        // Refill new date
        const sudoer = !!this.user?.sudoer?.id
        payload.obj.sudoer = sudoer
        if (sudoer) payload.obj.sudoerId = this.user?.sudoer?.id
        next(payload)
      })
    }
  }

  /**
   * This method redirects to the legacy dashboard. To be called if there is no
   * suitible route in this UI.
   */
  redirectToLegacy () {
    const now = Date.now()
    store.set('redirectTime', now)
    window.location = '/'
  }

  /**
   * Removes legacy login cookies.
   */
  simulateLegacyLogout () {
    cookies.remove('sx')
    cookies.remove('sd')
  }

  /**
   * Detects if we are redirecting to legacy app too quickly, as happens in dev
   * when there is no legacy app set up.
   */
  areWeInARedirectLoop () {
    const redirectTime = parseInt(store.get('redirectTime'))
    const now = Date.now()
    const sep = now - redirectTime
    debug({ redirectTime, now, sep })
    return sep < 2000
  }

  /**
   * Logs the user into the cognito service and gets tokens for use in our app.
   * Right now has code for legacy migration (setAuthenticationFlowType), but
   * this can eventually be cleaned up.
   *
   * Note that this method just logs the user in, It doesnt' directly take any
   * kind of navigation actions. Code that calls it can.
   */
  async logIn (username, password, attemptsSoFar = 0) {
    debug('logIn', { attemptsSoFar })
    const Username = (username || '').toLowerCase().trim()
    const Password = password
    const cred = new AuthenticationDetails({ Username, Password })
    runInAction(() => {
      this.cognitoUser = new CognitoUser({ Username, Pool: this.userPool })
      this.loggingIn = true
    })
    return new Promise((resolve, reject) => {
      this.cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')
      this.cognitoUser.authenticateUser(cred, {
        onSuccess: result => {
          store.remove('temporaryUsername')
          store.remove('emailForLogin')
          store.remove('lastPreLoginAction')
          // Instead of explicitly setting the state to logged in here, go
          // through a token refresh which will update this.state for us
          // runInAction(() => { this.state = states.loggedIn })
          this.refresh().then(() => {
            // Note that we don't do any navigation here, the caller should do it
            runInAction(() => {
              this.loggingIn = false
            })
            resolve()
          })
        },
        onFailure: err => {
          debug('onFailure', err.code, err)
          runInAction(() => {
            this.loggingIn = false
          })
          switch (err.code) {
            case 'UserNotConfirmedException':
              runInAction(() => {
                this.state = states.confirming
              })
              return reject(err)
            case 'UserNotFoundException':
            case 'NotAuthorizedException':
            case 'PasswordResetRequiredException':
            case 'InvalidUserPoolConfigurationException':
            case 'InvalidSmsRoleTrustRelationshipException':
            case 'InvalidSmsRoleAccessPolicyException':
            case 'InvalidParameterException':
            case 'InvalidLambdaResponseException':
              return reject(err)
            default:
              if (attemptsSoFar < 2) {
                return setTimeout(
                  () =>
                    this.logIn(username, password, attemptsSoFar + 1)
                      .then(resolve)
                      .catch(reject),
                  1000 * (attemptsSoFar + 1)
                )
              }
              return reject(err)
          }
        },
        newPasswordRequired: async (userAttributes, requiredAttributes) => {
          // https://www.npmjs.com/package/amazon-cognito-identity-js use case 23
          // the api doesn't accept this field back
          delete userAttributes.email_verified

          runInAction(() => {
            this.loggingIn = false
            this.userAttributes = userAttributes
            this.state = states.forceChangePassword
          })
          await this.refresh()
        },
        totpRequired: async () => {
          runInAction(() => {
            this.loggingIn = false
            this.state = states.sendMFACode
          })
          await this.refresh()
        }
      })
    })
  }

  async forceChangePassword (username, password) {
    if (!this.cognitoUser) throw new Error('You must log in first')
    // Remove userAttributes from completeNewPasswordChallenge due to aws policy changes
    return this.cognitoUser.completeNewPasswordChallenge(password, {}, {
      onSuccess: async cognitoUserSession => {
        store.remove('temporaryUsername')
        store.remove('emailForLogin')
        store.remove('lastPreLoginAction')
        await this.refresh()
        runInAction(() => {
          this.loggingIn = false
        })
      },
      onFailure: err => {
        debug('completeNewPasswordChallenge onFailure', err.code, err)
        throw new Error(err)
      }
    })
  }

  /**
   * Logs the user out of the cognito service and redirects to legacy logout.
   */
  logOut () {
    cookies.remove('st')
    if (this.cognitoUser) {
      this.cognitoUser.storage.clear()
      this.cognitoUser.signOut()
    }
    // This is the old way of doing it, but for now, we only need to redirect to
    // the legacy app to complete logout.
    runInAction(() => {
      this.cognitoUser = null
      // this.state = states.loggedOut
      this.state = states.booting
      // window.location = window.location // eslint-disable-line no-self-assign
      window.location = '/legacy-logout'
    })
  }

  async confirmRegistration (code, username) {
    if (username) {
      runInAction(() => {
        this.cognitoUser = new CognitoUser({
          Username: username,
          Pool: this.userPool
        })
      })
    }
    return new Promise((resolve, reject) => {
      // 2nd argument false prevents migrating previously used email/phone to
      // new username
      this.cognitoUser.confirmRegistration(code, false, (err, result) => {
        if (err) return reject(err)
        debug('result', result)
        runInAction(() => {
          this.state = states.loggedOut
        })
        return resolve()
      })
    })
  }

  // Maybe could be usefull for registration
  // Regrettably getAttributeVerificationCode not return data in onSuccess...
  // https://github.com/aws-amplify/amplify-js/blob/52bd2877bee66b934885e6726b68c8a926c28c3d/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1636
  async resendConfirmationCode () {
    return new Promise((resolve, reject) => {
      this.cognitoUser.resendConfirmationCode((err, result) => {
        if (err) reject(err)
        else resolve(result)
      })
    })
  }

  async forgotPassword (username) {
    runInAction(() => {
      this.cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool
      })
    })
    return new Promise((resolve, reject) => {
      this.cognitoUser.forgotPassword({
        onSuccess: data => {
          debug('result', data)
          store.set('temporaryUsername', username)
          // May use the email
          store.set('emailForLogin', username)
          store.set('lastPreLoginAction', 'forgotPassword')
          runInAction(() => {
            this.state = states.resetPassword
            this.emailForLogin = username
          })
          return resolve()
        },
        onFailure: reject
      })
    })
  }

  clearResetPassword () {
    runInAction(() => {
      store.remove('lastPreLoginAction')
      this.lastPreLoginAction = 'signUp'
    })
  }

  async resetPassword (verificationCode, newPassword) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: data => {
          debug('result', data)
          const username = this.cognitoUser.username || store.get('temporaryUsername')
          if (username) {
            const cred = new AuthenticationDetails({ Username: username, Password: newPassword })
            this.cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')
            this.cognitoUser.authenticateUser(cred, {
              onSuccess: result => {
                this.globalSignOut().then(() => resolve())
              },
              onFailure: err => {
                reject(err)
              }
            })
          }
          this.clearResetPassword()
          runInAction(() => {
            this.state = this.states.loggedOut
          })
          return resolve()
        },
        onFailure: reject
      })
    })
  }

  /**
   * Function to change a cognito user attribute.
   */
  async changeUserAttribute (Name, Value) {
    return new Promise((resolve, reject) => {
      const atts = [new CognitoUserAttribute({ Name, Value })]
      this.cognitoUser.updateAttributes(atts, (err, result) => {
        debug('cognitoUser.updateAttributes', err, result)
        if (err) return reject(err)
        resolve()
      })
    })
  }

  /**
   * Function to allow users to change password while logged in
   */
  async changeUserPassword (oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.changePassword(
        oldPassword,
        newPassword,
        (err, result) => {
          if (err) return reject(err)
          resolve(result)
        }
      )
    })
  }

  async globalSignOut () {
    return new Promise((resolve, reject) => {
      this.cognitoUser.globalSignOut({
        onSuccess: (msg) => {
          this.refresh().then(resolve)
        },
        onFailure: (err) => {
          reject(err)
        }
      })
    })
  }

  /**
   * Function to send email verification code to validate email
   * Maybe could be usefull for registration
   */
  async sendVerificationCode (code) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.verifyAttribute('email', code,
        {
          onFailure: reject,
          onSuccess: res => {
            runInAction(() => {
            })
            return resolve
          }
        }
      )
    })
  }

  /**
   * Verifies code to allow user to set MFA as required into their account
   * @param {String} code
   */
  async verifySoftwareToken (code) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.verifySoftwareToken(code, 'test', {
        onSuccess: (result) => {
          resolve('SUCCESS')
        },
        onFailure: (error) => {
          reject(error)
        }
      })
    })
  }

  async associateSoftwareToken () {
    return new Promise((resolve, reject) => {
      this.cognitoUser.associateSoftwareToken({
        associateSecretCode: resolve,
        onFailure: reject
      })
    })
  }

  async updateMFASetting (enabled) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.setUserMfaPreference(null,
        {
          PreferredMfa: enabled,
          Enabled: enabled
        },
        (err, result) => {
          if (err) return reject(err)
          resolve(result)
        }
      )
    })
  }

  /**
   * Sends code to aws for allow the login
   * @param {String} code
   */
  async sendMFACode2 (code) {
    if (!this.cognitoUser) throw new Error('You must log in first')
    return new Promise((resolve, reject) => {
      this.cognitoUser.sendMFACode(code, {
        onSuccess: async cognitoUserSession => {
          store.remove('temporaryUsername')
          store.remove('emailForLogin')
          store.remove('lastPreLoginAction')
          await this.refresh()
          runInAction(() => {
            this.loggingIn = false
          })
          resolve()
        },
        onFailure: reject
      }, 'SOFTWARE_TOKEN_MFA')
    })
  }

  /**
   * Loads intercom code.
   */
  async loadIntercom () {
    const sudoedByPlatformAdmin = this.user.sudoer
    if (IntercomEnabled && !sudoedByPlatformAdmin) {
      if (!window.Intercom) {
        // eslint-disable-next-line
        (function () { const w = window; const ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings) } else { const d = document; var i = function () { i.c(arguments) }; i.q = []; i.c = function (args) { i.q.push(args) }; w.Intercom = i; const l = function () { const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + IntercomAppId; const x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x) }; if (document.readyState === 'complete') { l() } else if (w.attachEvent) { w.attachEvent('onload', l) } else { w.addEventListener('load', l, false) } } })()
      }
      // Only boot the intercom if the legacyAccount is created
      if (window.Intercom && this.user && this.user.legacyAccountId) {
        window.Intercom('boot', {
          app_id: IntercomAppId,
          vertical_padding: 20,
          email: this.user.primaryEmail,
          user_id: this.user.legacyPrimary ? `${this.user.legacyAccountId}` : `${this.user.legacyAccountId}-${this.user.legacyUserId}`,
          company: {
            name: this.user.legacyAccountId,
            company_id: this.user.legacyAccountId
          }
        })
      }
    }
  }

  /**
   * Loads hotjar code.
   */
  async loadHotjar () {
    if (HotjarEnabled) {
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
        h._hjSettings = { hjid: HotjarAppId, hjsv: 6 }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script'); r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
      if (window.hj && this.user && this.user.legacyAccountId) window.hj('identify', `${this.user.legacyAccountId}`, {})
    }
  }
}

debug('loaded')
