import { marked } from 'marked'

/**
 * @param {{
 * markdown: string
 * className?: string
 * key?: string
 * }} props
 */
export const MarkdownRenderer = ({ markdown, className = '', key }) => {
  const html = marked.parse(markdown || '')

  return (
    <div
      key={key}
      className={className}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}
