import React from 'react'

const codeStyle = {
  padding: '1rem',
  color: '#f8f8f2',
  fontSize: '14px',
  overflowX: 'auto',
  lineHeight: '1.5',
  whiteSpace: 'pre',
  borderRadius: '8px',
  backgroundColor: '#2b2b2b',
  fontFamily: 'Fira Code, monospace'
}

/**
 * @param {{
 * children: React.JSX
 * language: String
 * className: String
 * key: String
 * }} props
 * @returns
 */
export const CodeBlock = ({ children, language, className, key }) => {
  return (
    <pre key={key} className={`code-block ${className}`} style={codeStyle}>
      <code className={`language-${language}`}>
        {children}
      </code>
    </pre>
  )
}
